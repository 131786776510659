import React from 'react';

import HeroHome from '../../assets/images/uf-home-hero-bg.jpg';
import HeroHome2x from '../../assets/images/uf-home-hero-bg@2x.jpg';

import HeroHomeWebp from '../../assets/images/uf-home-hero-bg.webp';
import HeroHome2xWebp from '../../assets/images/uf-home-hero-bg@2x.webp';

import HeroHome_s from '../../assets/images/uf-home-hero-bg_s.jpg';
import HeroHome_s2x from '../../assets/images/uf-home-hero-bg_s@2x.jpg';

import HeroHome_sWebp from '../../assets/images/uf-home-hero-bg_s.webp';
import HeroHome_s2xWebp from '../../assets/images/uf-home-hero-bg_s@2x.webp';

function UFHero() {
  return (
    <section className="relative uf-hero-container">
      <div className="container mx-auto">
        <div className="text-white-100 flex flex-col items-center justify-center w-100">
          <h1 className="text-center">
            After years of heavy bleeding, <br /> Myfembree made lighter periods
            possible.
          </h1>
          <div className='w-[100%] max-w-[1304px] text-center md:text-end'>
              <small className="block text-white-100">
            Not actual patients throughout
          </small>
          </div>

  

          <div className="absolute left-0 top-0 flex flex-col md:flex-row uf-hero-image">
            <picture className="relative">
              <source
                srcSet={`${HeroHome_s2xWebp}, ${HeroHome_s2xWebp} 2x`}
                media="(max-width: 896px)"
                type="image/webp"
              />

              <source
                srcSet={`${HeroHome_s2x}, ${HeroHome_s2x} 2x`}
                media="(max-width: 896px)"
              />

              <source
                srcSet={`${HeroHome2xWebp}, ${HeroHome2xWebp} 2x`}
                type="image/webp"
                media="(min-width: 896px)"
              />

              <source
                srcSet={`${HeroHome2x}, ${HeroHome2x} 2x`}
                media="(min-width: 896px)"
              />

              <img
                className="w-100 img-fluid"
                src={HeroHome_s2xWebp}
                alt="Actor portrayal of a woman meditating."
                // width={720}
                // height={626}
                fetchpriority="high"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UFHero;
