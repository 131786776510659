import React from 'react';

function UfOffer() {
  return (
    <section className="uf-offer-section">
      <div className="container mx-auto">
        <p className="flex mx-auto relative text-center md:text-start">
          <span className="font-semibold">
            <span className="asterisk">*</span>
            Offer not valid for patients enrolled in Medicare, Medicaid, or
            other government healthcare programs. With this Copay Program,
            eligible patients may pay as little as $5 per prescription for up to
            an 84-day supply of Myfembree. This Copay Program is subject to a
            calendar year maximum savings of $5,000. After the calendar year
            maximum savings is reached, patient will be responsible for the
            remaining out-of-pocket costs for Myfembree.
          </span>
        </p>
      </div>
    </section>
  );
}

export default UfOffer;
