import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';

import myfembreeIcon from '../../assets/images/myfembree-icon.svg';
import doctorIcon from '../../assets/images/doctor-icon.svg';
import dollarIcon from '../../assets/images/dollar-icon.svg';

import useIsEdge from '../../util/use-is-edge';

const cardList = [
  {
    icon: myfembreeIcon,
    iconW: 68,
    iconH: 76,
    title: 'What is Myfembree?',
    text: (
      <>
        Myfembree is the first and only FDA-approved once-daily pill to reduce
        heavy menstrual <br className="hidden xl:block" /> bleeding from uterine
        fibroids (UF) in premenopausal women.
      </>
    ),
    btn: <>SEE PROVEN RESULTS</>,
    link: '/uterine-fibroids/why-myfembree/',
    terms: false,
    alt: ''
  },
  {
    icon: doctorIcon,
    iconW: 68,
    iconH: 82,
    title: 'Ready to ask about Myfembree?',
    text: (
      <>
        Tired of the constant disruption caused by heavy periods from uterine
        fibroids? Ask your gynecologist if Myfembree could help make lighter{' '}
        <br className="hidden xl:block" />
        periods possible.
      </>
    ),
    btn: (
      <>
        START THE <br className="md:hidden" /> CONVERSATION
      </>
    ),
    link: '/uterine-fibroids/at-the-doctor',
    terms: false,
    alt: ''
  },
  {
    icon: dollarIcon,
    iconW: 44,
    iconH: 83,
    title: 'How can you save on Myfembree?',
    text: (
      <>
        If you have commercial insurance, you could pay{' '}
        <br className="hidden xl:block" /> as little as $5 for your Myfembree
        prescription <br className="hidden xl:block" /> (up to $5,000 per
        calendar year).*
      </>
    ),
    btn: (
      <>
        EXPLORE MYFEMBREE <br className="md:hidden" /> COST SAVINGS
      </>
    ),
    link: '/uterine-fibroids/cost-and-support/',
    terms: true,
    alt: ''
  }
];

function UFCards() {
  const isEdge = useIsEdge();

  return (
    <section>
      <div className={`container mx-auto ${isEdge ? 'is-edge-container' : ''}`}>
        <ul className="flex flex-wrap lg:flex-nowrap uf-cards-list mx-auto">
          {cardList.map((card, index) => {
            return (
              <li className="flex flex-col items-center" key={index}>
                <img
                  src={card.icon}
                  alt={card.alt}
                  width={card.iconW}
                  height={card.iconH}
                />

                <h2 className="font-mulish font-bold text-white-100 text-center">
                  {card.title}
                </h2>

                <p className="text-white-100 text-center relative">
                  {card.text}
                </p>

                {index === 0 && (
                  <p className="text-white-100 should-not text-center">
                    Myfembree should not be taken for more than 24 months.
                  </p>
                )}

                <Link className="font-mulish text-center" to={card.link}>
                  {card.btn}
                </Link>

                {card.terms && (
                  <Link
                    className="terms"
                    to="/cost-and-support/#terms-and-conditions"
                  >
                    TERMS AND CONDITIONS
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default UFCards;
