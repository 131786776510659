import React from 'react';

import focusOnBg from '../../assets/images/focus-on-bg.jpg';
import focusOnBgWebp from '../../assets/images/focus-on-bg.webp';

import focusOnBg2x from '../../assets/images/focus-on-bg@2x.jpg';
import focusOnBgWebp2x from '../../assets/images/focus-on-bg@2x.webp';

import focusOnBg_s from '../../assets/images/focus-on-bg_s.jpg';
import focusOnBgWebp_s from '../../assets/images/focus-on-bg.webp';

import focusOnBg_s2x from '../../assets/images/focus-on-bg_s@2x.jpg';
import focusOnBgWebp_s2x from '../../assets/images/focus-on-bg_s@2x.webp';

function FocusOn() {
  return (
    <section className="uf-relief relative">
      <div className="container mx-auto container-uf">
        <h2 className="text-white-100 text-center">
          <span className="block text-center mx-auto">
            I’ve got so many things to focus on. Heavy periods due to uterine
            fibroids shouldn’t be one of them.
          </span>
        </h2>
      </div>

      <picture className="uf-relief-img absolute top-0">
        <source
          srcSet={`${focusOnBgWebp_s2x}, ${focusOnBgWebp_s2x} 2x`}
          media="(max-width: 896px)"
          type="image/webp"
        />

        <source
          srcSet={`${focusOnBg_s2x}, ${focusOnBg_s2x} 2x`}
          media="(max-width: 896px)"
        />

        <source
          srcSet={`${focusOnBgWebp2x}, ${focusOnBgWebp2x} 2x`}
          type="image/webp"
          media="(min-width: 896px)"
        />

        <source
          srcSet={`${focusOnBg2x}, ${focusOnBg2x} 2x`}
          media="(min-width: 896px)"
        />

        <img
          className="w-100 img-fluid"
          src={focusOnBgWebp_s2x}
          alt="Actor portrayal of a woman who takes MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) tablets taking pictures of her daughters at soccer practice"
          width={1440}
          height={587}
        />
      </picture>
    </section>
  );
}

export default FocusOn;
